export class Job{
    id:number
    company_id:number
    url:string
    name:string
    description:string
    location:string
    deadline:Date
    cv_required:boolean
}

export class JobAnswer{
    data:Job[]
}

export class OneJobAnswer{
    data:Job
}