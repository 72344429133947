import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Job, JobAnswer, OneJobAnswer } from '../models/job';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private httpClient:HttpClient) { }
  public getJobsForCompany(comapnyId:number):Observable<JobAnswer>{
    return this.httpClient.get<JobAnswer>(environment.API_BASE+"/items/jobs?filter[company_id][eq]="+comapnyId);
  }

  public createJob(job:Job,token:string):Observable<OneJobAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.post<OneJobAnswer>(environment.API_BASE+"/items/jobs",job,{headers: authHeaders});
  }
  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
