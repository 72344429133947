import { Answer } from "./answer"

export class Question{
    id:number
    title:string
    description:string
    max_length:number
    answer:Answer
    job_id:number
}
export class QuestionAnswer{
    data:Question[]
}

export class OneQuestionAnwer{
    data:Question
}