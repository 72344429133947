import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompanyAnswer, OneCompanyAnswer } from '../models/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpClient:HttpClient) { }

  public getMyCompany(userId:number,token:string):Observable<CompanyAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<CompanyAnswer>(environment.API_BASE+"/items/companies?fields=*,logo.*&filter[owner][eq]="+userId,{headers:authHeaders});
  }
  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
