export class Auth{
    email:string
    password:string
}
export class RegisterUser{
    status:string
    role:number
    first_name:string
    last_name:string
    email:string
    password:string
}

export class LoginUser{
    email:string
    password:string
}

export class LoginAnswer{
    data:TokenData
}
export class TokenData{
    token:string
    user:UserData
}
export class UserData{
    id:number
    external_id:string
    first_name:string
    last_name:string
    email:string
}