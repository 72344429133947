import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnswerResult } from '../models/answer';
import { Application, ApplicationAnswer } from '../models/applications';
import { Company, CompanyAnswer } from '../models/company';
import { Job, JobAnswer } from '../models/job';
import { Question, QuestionAnswer } from '../models/question';
import { AnswerService } from '../services/answer.service';
import { ApplicationService } from '../services/application.service';
import { CompanyService } from '../services/company.service';
import { JobService } from '../services/job.service';
import { QuestionService } from '../services/question.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public company: Company;
  public jobs: Job[];
  public selectedJob: Job;
  public applications: Application[];
  public selectedApplication: Application;
  public questions:Question[];
  public question:Question;

  constructor(
    private companyService: CompanyService,
    private jobService: JobService,
    private applicationService: ApplicationService,
    private questionService:QuestionService,
    private answerService:AnswerService,
    private domSanitizer: DomSanitizer,
    private router:Router
  ) { }

  ngOnInit() {
    this.question = new Question();
    this.loadCompany()
  }

  private loadCompany() {
    let token = localStorage.getItem('token');
    let userId = Number(localStorage.getItem('user_id'));
    this.companyService.getMyCompany(userId, token).subscribe((result: CompanyAnswer) => {
      this.company = result.data[0];
      this.loadJobsForCompany(this.company.id);
    });
  }
  private loadJobsForCompany(companyId: number) {
    this.jobService.getJobsForCompany(companyId).subscribe((result: JobAnswer) => {
      this.jobs = result.data;
      console.log(this.jobs);
    });
  }
  public loadJob(job: Job) {
    this.selectedJob = job;
    this.loadApplicationsForJob(this.selectedJob.id);
    this.loadQuestionsForJob(this.selectedJob.id);
    this.question.job_id = job.id;
  }
  private loadQuestionsForJob(jobId:number){
    this.questionService.getQuestions(jobId).subscribe((result:QuestionAnswer)=>{
      this.questions = result.data;
    });
  }
  private loadApplicationsForJob(jobId: number) {
    let token = localStorage.getItem('token');
    this.applicationService.getApplicationsForJob(jobId, token).subscribe((result: ApplicationAnswer) => {
      this.applications = result.data;
    });
  }
  public openApplication(application: Application) {
    this.selectedApplication = application;
    this.questions.forEach(question=>{
      this.loadAnswers(question.id);
    });
  }
  private loadAnswers(questionId: number) {
    let token = localStorage.getItem('token');
    this.answerService.getAnswers(this.selectedApplication.id, questionId, token).subscribe((result: AnswerResult) => {
      this.questions.forEach(question => {
        if (question.id == questionId) {
          question.answer = result.data[0];
          console.log(question);
        }
      });
    });
    
  }
  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  addJob(){
    this.router.navigate(["/add-job"]);
  }

  addQuestion(){
    console.log(this.question);
    let token = localStorage.getItem('token');
    this.questionService.createQuestion(this.question,token).subscribe(data=>{
      console.log(data);
      this.question = new Question();
      this.question.job_id = this.selectedJob.id;
      this.loadQuestionsForJob(this.selectedJob.id);
    },error=>{
      console.log(error);
    })
  }
}
