import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginAnswer, LoginUser } from '../models/auth';
import { Error } from '../models/error';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  public loginUser:LoginUser;
  public isLoading:boolean;
  constructor(
    private userService:UserService,
    private router:Router
  ) { }

  ngOnInit() {
    this.loginUser = new LoginUser();
  }

  public login(){
    this.userService.login(this.loginUser).subscribe((result:LoginAnswer)=>{
      this.parseLoginData(result);
      this.router.navigate(["/"]);
    },(error:Error)=>{
      console.log(error);
      this.isLoading = false;
      let code = error.error.error.code;
      if(code==100){
        this.showError("User credentials are wrong!");
      }
    });
  }
  private parseLoginData(loginData:LoginAnswer){
    localStorage.setItem('token', loginData.data.token);
    localStorage.setItem('user_id', loginData.data.user.id.toString());
  }
  private showError(error:string){
    alert(error);
  }
}
