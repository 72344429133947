import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationAnswer } from '../models/applications';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private httpClient:HttpClient) { }
  getApplicationsForJob(jobId: number, token: string):Observable<ApplicationAnswer> {
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<ApplicationAnswer>(environment.API_BASE+"/items/applications?filter[job_id][eq]="+jobId+"&fields=*,cv.*",{headers: authHeaders});
  }
  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
