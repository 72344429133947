import { Component, OnInit } from '@angular/core';
import { Company, CompanyAnswer } from '../models/company';
import { Job } from '../models/job';
import { CompanyService } from '../services/company.service';
import { JobService } from '../services/job.service';

@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.css']
})
export class AddJobComponent implements OnInit {

  constructor(
    private companyService:CompanyService,
    private jobService:JobService) { }

  public company:Company;
  public job:Job;
  ngOnInit() {
    this.job= new Job();
    this.loadCompany()
  }

  private loadCompany() {
    let token = localStorage.getItem('token');
    let userId = Number(localStorage.getItem('user_id'));
    this.companyService.getMyCompany(userId, token).subscribe((result: CompanyAnswer) => {
      this.company = result.data[0];
      this.job.company_id =this.company.id;
      console.log(this.company);
    });
  }

  public sendJob(){
    console.log(this.job);
    
    let token = localStorage.getItem('token');
    this.jobService.createJob(this.job,token).subscribe(data=>{
      console.log(data);
    },error =>{
      console.log(error);
    })
  }
}
