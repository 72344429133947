import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OneQuestionAnwer, Question, QuestionAnswer } from '../models/question';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private httpClient:HttpClient) {
  }

  public getQuestions(jobId:number){
    return this.httpClient.get<QuestionAnswer>(environment.API_BASE+"/items/questions?filter[job_id][eq]="+jobId);
  }
  public createQuestion(question:Question,token:string):Observable<OneQuestionAnwer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.post<OneQuestionAnwer>(environment.API_BASE+"/items/questions",question,{headers: authHeaders});
  }
  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
